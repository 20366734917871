.signature {
    padding: 32px 0;
}

.signature__widget {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
}

.signature__title {
    padding: 20px 40px;
    border-bottom: 1px solid rgba(68, 83, 113, 0.20);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.signature__title h1 {
    margin: 0;
    color: #0E1117;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.32px;
}

.signature__row {
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.signature__data {
    flex: 1;
    width: 100%;
}

.signature__information {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    column-gap: 20px;
}

.signature__panel {
    width: 100%;
    height: 100%;
}

.signature__img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 6px;
}

.label--column-2 {
    grid-column: 2 / span 2;
}

.label--column-3 {
    grid-column: 1 / span 3;
}

.documentation {
    background-color: #fff7c4;
    border: 1px solid #000;
    border-radius: 10px;
    display: inline-flex;
    margin: 32px 0 0 0;
    padding: 8px 16px;
}

.signature__manual {
    margin: 0;
    color: #AF2727;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.12px;
    text-decoration: underline;
    text-transform: uppercase;
}

@media (max-width: 1140px) {
    .signature__title {
        padding: 20px;
    }

    .signature__row {
        gap: 20px;
        padding: 20px;
    }

    .signature__information {
        grid-template-columns: 1fr;
    }

    .label--column-2 {
        grid-column: 1 / span 1;
    }
}

@media (max-width: 576px) {
    .signature__row {
        flex-direction: column;
        align-items: center;
    }
}

.option {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.option__text {
    color: #69758E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.option__input {
    background-color: #fff;
    border: 1px solid #2121211a;
    border-radius: 8px;
    color: #b3b3b3;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    padding: 12px;
    transition: border .3s, box-shadow .3s, color 0.3s;
    width: 100%;

    &:focus {
        color: #2E3A4C;
    }
}

.label:hover .input {
    border: 1px solid #21212126;
    box-shadow: 0 4px 4px 0 #8e8dd01f;
}
.label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.text {
    color: #69758E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.input {
    background-color: #fff;
    border: 1px solid #2121211a;
    border-radius: 8px;
    color: #b3b3b3;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    padding: 12px;
    transition: border .3s, box-shadow .3s, color 0.3s;
    width: 100%;

    &:focus {
        color: #2E3A4C;
    }
}

.label:hover .input {
    border: 1px solid #21212126;
    box-shadow: 0 4px 4px 0 #8e8dd01f;
}
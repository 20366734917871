* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1216px;
  width: 100%;
  margin: 0 auto;
  padding: 0 14px;
}

.btn-download {
  align-items: baseline;
  border: 1px solid #0000;
  background-color: rgba(68, 83, 113, 0.10);
  border-radius: 8px;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  justify-content: center;
  line-height: 120%;
  outline: none;
  padding: 8px 12px;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: .3s;
  max-width: 110px;
  width: 100%;
}

.btn-download:hover {
  background-color: rgba(68, 83, 113, 0.15);
}

.btn-download::before {
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.9999 0.399902C7.33127 0.399902 7.5999 0.668532 7.5999 0.999902V6.21804L9.24231 4.57564C9.47662 4.34132 9.85652 4.34132 10.0908 4.57564C10.3251 4.80995 10.3251 5.18985 10.0908 5.42417L7.42417 8.09083C7.18985 8.32515 6.80995 8.32515 6.57564 8.09083L3.90897 5.42417C3.67466 5.18985 3.67466 4.80995 3.90897 4.57564C4.14329 4.34132 4.52319 4.34132 4.7575 4.57564L6.3999 6.21804V0.999902C6.3999 0.668532 6.66853 0.399902 6.9999 0.399902ZM0.999903 6.3999C1.33127 6.3999 1.5999 6.66853 1.5999 6.9999V9.7999C1.5999 10.3699 1.60037 10.7612 1.62516 11.0646C1.64938 11.361 1.69378 11.5203 1.75249 11.6355C1.88672 11.8989 2.10089 12.1131 2.36432 12.2473C2.47955 12.306 2.63877 12.3504 2.93517 12.3746C3.23857 12.3994 3.62995 12.3999 4.1999 12.3999H9.7999C10.3699 12.3999 10.7612 12.3994 11.0646 12.3746C11.361 12.3504 11.5203 12.306 11.6355 12.2473C11.8989 12.1131 12.1131 11.8989 12.2473 11.6355C12.306 11.5203 12.3504 11.361 12.3746 11.0646C12.3994 10.7612 12.3999 10.3699 12.3999 9.7999V6.9999C12.3999 6.66853 12.6685 6.3999 12.9999 6.3999C13.3313 6.3999 13.5999 6.66853 13.5999 6.9999V9.82501C13.5999 10.3637 13.5999 10.8043 13.5707 11.1624C13.5404 11.533 13.4758 11.8677 13.3165 12.1803C13.0672 12.6695 12.6695 13.0672 12.1803 13.3165C11.8677 13.4758 11.533 13.5404 11.1624 13.5707C10.8043 13.5999 10.3637 13.5999 9.82501 13.5999H4.17479C3.63608 13.5999 3.1955 13.5999 2.83745 13.5707C2.46684 13.5404 2.13212 13.4758 1.81953 13.3165C1.33031 13.0672 0.932557 12.6695 0.683286 12.1803C0.524012 11.8677 0.459424 11.533 0.429144 11.1624C0.39989 10.8043 0.399896 10.3637 0.399903 9.82504L0.399903 6.9999C0.399903 6.66853 0.668532 6.3999 0.999903 6.3999Z' fill='black'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-position: center;
}
.widget {
    margin-top: 32px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.widget__top {
    position: relative;
    padding: 12px 40px;
    border-bottom: 1px solid rgba(68, 83, 113, 0.20);
}

.widget__top .swiper-button-next:after,
.widget__top .swiper-button-prev:after {
    font-size: 26px;
    font-weight: 600;
    color: #445371;
}

.widget__swiper-thumbs {
    padding: 6px 4px;
    border-radius: var(--radius-lg, 8px);
    border: 1px solid rgba(68, 83, 113, 0.20);
    background: #FFF;
}

.widget__swiper-thumbs .swiper-slide {
    max-width: max-content;
    width: 100%;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

.widget__swiper-thumbs .swiper-slide img {
    display: block;
    max-width: 85%;
    height: 24px;
}

.widget__swiper-thumbs .swiper-slide.swiper-slide-thumb-active {
    background-color: rgba(68, 83, 113, 0.05);
}

.widget__bottom {
    padding: 20px 40px;
}

.widget__swiper .swiper-slide {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 16px;
}

@media (max-width: 576px) {
    .widget__bottom {
        padding: 20px;
    }
}
@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Regular/Roboto-Regular.eot');
    src: url('./Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Regular/Roboto-Regular.woff2') format('woff2'),
        url('./Roboto-Regular/Roboto-Regular.woff') format('woff'),
        url('./Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
        url('./Roboto-Regular/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Medium/Roboto-Medium.eot');
    src: url('./Roboto-Medium/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Medium/Roboto-Medium.woff2') format('woff2'),
        url('./Roboto-Medium/Roboto-Medium.woff') format('woff'),
        url('./Roboto-Medium/Roboto-Medium.ttf') format('truetype'),
        url('./Roboto-Medium/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./Roboto-Bold/Roboto-Bold.eot');
    src: url('./Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Roboto-Bold/Roboto-Bold.woff2') format('woff2'),
        url('./Roboto-Bold/Roboto-Bold.woff') format('woff'),
        url('./Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
        url('./Roboto-Bold/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
.block {
  width: 162px;

  &__user {
    position: relative;
    width: 162px;
    height: 162px;
    border-radius: 50%;
    border: 3px solid #F5F5F5;
  }

  &__hint {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #97a2b6;
    text-align: center;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: #8892a4; /* Темнее на 10% */
    }
  }

  &__desc {
    margin: 0;
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    & span {
      text-decoration: underline;
    }
  }

  &__type {
    margin: 0;
    color: #69758E;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  &__svg {
    fill: #99A1B7;
    transition: fill 0.3s ease;

    &:hover {
      fill: #0E1117;
    }
  }

  &__input {
    display: none;
  }

  &__avatar {
    width: 100%;
    height: 100%;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  &__panel {
    margin: 8px 0;
    border-radius: 10px;
    background: #F5F5F5;
    border: 4px solid #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }

  &__load {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #303A4F;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 6px;
    background: #FFF;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.75;
    }
  }

  &__btn {
    width: 29px;
    height: 25px;
    padding: 4px 6px;
    border-radius: 6px;
    background: #FFF;
    cursor: pointer;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.75;
    }
  }

  &__row {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 20px;
  }

  &__plugin {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
  }

  &__edit {
    max-width: 260px;
    width: 100%;
    border-radius: 15px;
    border-bottom: 1px solid rgba(68, 83, 113, 0.20);
    background: #FFF;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    text-align: center;
    color: #0E1117;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.32px;
    padding: 10px;
    border-bottom: 1px solid rgba(68, 83, 113, 0.20);
  }

  &__setting {
    display: flex;
    flex-direction: column;
    padding: 26px 20px 20px 20px;
    height: 100%;
  }

  &__setting {

  }

  &__bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__file {
    display: none;
  }

  &__open {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: rgba(68, 83, 113, 0.10);
    color: #303A4F;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 20px;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .block__svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  &__save {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #AF2727;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 20px;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  &__save {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #AF2727;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 20px;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  &__range {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__number {
    width: 50px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid rgba(68, 83, 113, 0.20);
    background: #FFF;
    overflow: hidden;
    color: #0E1117;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__slider[type=range] {
    -webkit-appearance: none; /* Убираем стандартное отображение */
    width: 100%;
    background: transparent; /* Убираем фон для самого ползунка */
  }

  &__slider[type=range] {
    /* Установка начального значения для CSS-переменной */
    background: linear-gradient(
                    to right,
                    #445371 var(--value), /* Темный цвет для пройденной области */
                    #edeef1 var(--value) /* Светлый цвет для непройденной области */
    );
    border-radius: 25px;
  }

  &__slider[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 25px; /* Закругленные края */
  }

  &__slider[type=range]::-webkit-slider-thumb {
    height: 12px;
    width: 24px;
    background: #445371; /* Цвет ползунка */
    border-radius: 20px; /* Закругляем ползунок */
    cursor: pointer;
    -webkit-appearance: none; /* Убираем стандартное отображение */
    margin-top: -3px; /* Устанавливаем позицию ползунка по центру трека */
  }
}

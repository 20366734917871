.header {
    padding: 32px 0;
    color: #303A4F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.logo {
    max-width: 154px;
    display: flex;
    width: 100%;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.link {
    text-decoration: none;
    margin: 0;
    color: #303A4F;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.12px;
    transition: color 0.3s;
}

.linkActive {
    color: #AF2727;
    user-select: none;
}

.turbo {
    max-width: 400px;
    width: 100%;
}

.turbo__top {
    margin: 0;
    color: #AF2727;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.12px;
    text-decoration: underline;
    text-transform: uppercase;
}

.turbo__center {
    margin: 0;
    color: #303A4F;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.12px;
}

.turbo__bottom {
    margin: 0;
    color: #303A4F;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.24px;
}

@media (max-width: 992px) {
    .logo {
        max-width: 124px;
    }

    .turbo__top,
    .turbo__center {
        font-size: 20px;
    }

    .turbo__bottom {
        font-size: 12px;
    }
}
@media (max-width: 576px) {
    .row {
        flex-direction: column;
    }
    .turbo {
        text-align: center;
    }
}

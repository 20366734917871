.block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.block__row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 24px;
}

.block__panel {
    position: relative;
    width: 384px;
    padding-bottom: 40px;
}

.block__aut {
    padding: 40px;
    border-radius: 30px;
    background: #FFF;
}

.block__title {
    margin: 0;
    color: #303A4F;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.12px;
}

.block__title span {
    color: #AF2727;
}

.block__form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.block__btn {
    align-items: center;
    border: 1px solid #0000;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    gap: 8px;
    justify-content: center;
    line-height: 120%;
    max-width: 100%;
    outline: none;
    padding: 14px 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    transition: .3s;
    width: 100%;
    background-color: #b12933;
}

.block__btn:hover {
    background-color: #95000b;
}

.block__error {
    position: absolute;
    bottom: 0;
    left: 10px;
    margin: 0;
    font-size: 14px;
    transition: 0.3s;
    color: #b12933;
}

.block__picture {
    flex: 1;
}

.block__img {
    width: 100%;
    display: block;
}

@media (max-width: 768px) {
    .block__aut {
        padding: 40px 20px;
    }
    .block__picture {
        display: none;
    }
}
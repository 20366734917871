.modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  z-index: 11;

  &__block {
    position: relative;
    max-width: 960px;
    width: 100%;
    border: 1px solid rgba(68, 83, 113, 0.20);
    border-radius: 15px;
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
    z-index: 12;
    overflow: hidden;
  }

  &__heading {
    background: #FFF;
    padding: 20px;
    border-bottom: 1px solid rgba(68, 83, 113, 0.20);
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    stroke: #303A4F;
    cursor: pointer;
    transition: stroke 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      stroke: #AF2727;
    }
  }

  &__title {
    margin: 0;
    color: #303A4F;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  &__overlay {
    position: fixed;
    inset: 0;
    background: #000;
    opacity: 0.6;
    z-index: 2;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  &__content {
    background: #F0F1F3;
  }
}
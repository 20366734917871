.block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.title {
    margin: 0;
    color: #303A4F;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.12px;
}

.subtitle {
    color: #2e3a4c;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    margin: 0;
}

.link {
    text-decoration: none;
    color: #AF2727;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition: opacity 0.3s;
}

.link:hover {
    opacity: 0.5;
}